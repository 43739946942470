<template>
    <div :class="['card']">
        <div class="card-content">
            <div class="card-content__img">
                <img :src="item.imageIcon" :alt="item.name">
            </div>
            <h4 :class="[`text-${color}`]">{{ item.name }}</h4>
            <p>{{ item.desc }}</p>
        </div>
        <router-link :to="link" :title="linkToTitle" :class="['btn btn-more mt-auto', `bg-${color}`, `border-${color}`]">
            Mehr Tipps 
            <span class="material-icons-outlined show-ltr" aria-hidden="true">navigate_next</span>
            <span class="material-icons-outlined show-rtl" aria-hidden="true">navigate_before</span>     
        </router-link>
    </div>
</template>

<script>
    export default {
        name: 'RatgeberKategorieCard',
        props: {
            item: {
                type: Object,
                required: true
            },
            color: {
                type: String,
                default: '',
            }
        },
        computed: {
            link(){
                var cat = "default";
                if(this.item.slug!==null && this.item.slug!==undefined){
                    cat = this.item.slug.replaceAll(' ', '');
                }
                return "/ratgeber/"+cat;
            },
            linkToTitle(){
                return "Mehr Informationen zu: "+this.item.name;
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import '../../scss/_variables.scss';

    .card {
        display: flex;
        position: relative;
        min-width: 200px;
        max-width: 200px;
        border: none;
        border-radius: 0px;
        margin: 10px 0;
        margin-right: 15px;
        box-shadow: 1px 2px 10px 1px #00000040;

        &-content {
            padding: 20px 12px;

            &__img {
                display: block;
                text-align: center;
                margin-top: 20px;
                margin-bottom: 30px;

                img{
                    height: 110px;
                }

            }

            h4 {
                font-size: 16px;
                margin-bottom: 15px;
                color: $primary;
            }
            p {
                font-size: 14px;
            }

        }

        &:first-child {
            margin-left: 15px;
        }
    }

    body[dir="rtl"] {
        .card{
            margin-right: 0;
            margin-left: 15px;

            &:first-child {
                margin-right: 15px;
            }
        }
    }
</style>
